import type { TooltipComponentFormatterCallback, TooltipComponentFormatterCallbackParams } from "echarts";
import type { BarSeriesOption } from "echarts/charts";
import { useState } from "react";
import { Label } from "react-aria-components";
import invariant from "tiny-invariant";
import { BadgeDot } from "../../components/Badge.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { Separator } from "../../components/Separator.tsx";
import { useConfig } from "../../config.ts";
import { useThemeVar } from "../../modules/theme/useTheme.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { usePortal } from "../../utils/react/usePortal.tsx";
import { monthlyArpaQueryAtom } from "../accounts/accountsQuery.ts";
import { CustomRadios, type FilterItem } from "./CustomRadios.tsx";
import { LightBlock } from "./LightBlock.tsx";
import { PercentageChange } from "./PercentageChange.tsx";
/* eslint-disable react-hooks/rules-of-hooks -- Work around ESLint bug not allowing hooks and loops in the same component regardless of order */
import { HeadingBlock } from "./HeadingBlock.tsx";
import { monthNames, WBarChart, type WBarChartOptions } from "./WBarChart.tsx";

type FilterBy = "paying_ba_count" | "arpa";

function CustomersSectionContent() {
    const limitMonths = 12;
    const { allowDebt } = useConfig();

    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const afterDate = `${year}-${month}-${day}`;

    const items = useSuspenseQueryAtom(monthlyArpaQueryAtom(afterDate, allowDebt));
    const [filterBy, setFilterBy] = useState<FilterBy>("paying_ba_count");
    const tooltipPortal = usePortal();

    const colorText = useThemeVar("color-text");
    const colorPrimary = useThemeVar("color-primary");
    const colorPrimaryDark = useThemeVar("color-primary-dark");

    const xAxisData: string[] = [];

    for (let iteratorMonths = limitMonths; iteratorMonths > 0; iteratorMonths--) {
        const generatedDate = new Date();
        generatedDate.setMonth(generatedDate.getMonth() - (iteratorMonths - 1));
        const month = monthNames[generatedDate.getMonth()];
        const year = generatedDate.getFullYear();
        xAxisData.push(`${month} ${year}`);
    }

    let payingTotalLastMonth = 0;
    let payingTotalPrevMonth = 0;
    let arpaTotalLastMonth = 0;
    let arpaTotalPrevMonth = 0;
    const indexPrevARPA: number = allowDebt ? 2 : 3;
    const indexLastARPA: number = allowDebt ? 1 : 2;

    items.forEach((item, index) => {
        if (index + indexPrevARPA === items.length) {
            payingTotalPrevMonth = item.paying_ba_count;
            arpaTotalPrevMonth = item.arpa;
        }
        if (index + indexLastARPA === items.length) {
            payingTotalLastMonth = item.paying_ba_count;
            arpaTotalLastMonth = item.arpa;
        }
    });

    const tooltipFormatter: TooltipComponentFormatterCallback<TooltipComponentFormatterCallbackParams> = (params) => {
        invariant(!Array.isArray(params), "Expected single params");
        const { name, value, dataIndex } = params;
        invariant(typeof value === "number", "Expected number value");

        const content = (
            <div>
                <div>
                    <b>{name}</b>
                </div>

                <BadgeDot color="color-primary">
                    Paying BA: <b>{items[dataIndex].paying_ba_count}</b>
                </BadgeDot>

                <BadgeDot color="color-primary-dark">
                    ARPA:{" "}
                    <b>
                        <CurrencyBalance value={items[dataIndex].arpa} />
                    </b>
                </BadgeDot>
            </div>
        );

        // return renderToStaticMarkup(content);
        tooltipPortal.setContent(content);
        return tooltipPortal.element;
    };

    const seriesData: BarSeriesOption[] = [
        {
            name: filterBy,
            type: "bar",
            stack: "total",
            itemStyle: {
                color: filterBy === "paying_ba_count" ? colorPrimary : colorPrimaryDark,
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data: items.map((item) => item[filterBy]),
        },
    ];

    const filterItems: FilterItem[] = [
        { key: "paying_ba_count", title: "Paying BA" },
        { key: "arpa", title: "ARPA" },
    ];

    const options: WBarChartOptions = {
        tooltip: {
            trigger: "item",
            axisPointer: {
                type: "none",
            },
            borderWidth: 0,
            textStyle: {
                color: colorText,
            },
            extraCssText: "box-shadow: 0 8px 20px rgba(0 0 0 / 10%);",
        },
        grid: {
            top: "5px",
            left: "5px",
            right: "5px",
            bottom: "5px",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: xAxisData,
            axisLine: {
                lineStyle: {
                    color: "#888888",
                },
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
        },
        series: seriesData,
    };

    return (
        <>
            {tooltipPortal.portalNode}

            <div className="flex justify-between gap-1rem pb-3">
                <div>
                    <h2 className="font-size-heading">Customers</h2>
                    <p className="pb-3">Paying billing accounts (BA) and average revenue per billing account (ARPA)</p>
                </div>

                <HeadingBlock title="Paying BAs last month" subtitle={payingTotalLastMonth}>
                    <PercentageChange last={payingTotalLastMonth} prev={payingTotalPrevMonth} />
                </HeadingBlock>

                <HeadingBlock title="ARPA last month" subtitle={<CurrencyBalance value={arpaTotalLastMonth} />}>
                    <PercentageChange last={arpaTotalLastMonth} prev={arpaTotalPrevMonth} />
                </HeadingBlock>
            </div>

            <Separator />

            <div className="flex justify-between pt-3 pb-3">
                <div className="flex gap-1rem font-size-small">
                    <BadgeDot color={filterBy === "paying_ba_count" ? "color-primary" : "color-primary-dark"}>
                        {filterBy === "paying_ba_count" ? "Paying BA" : "ARPA"}
                    </BadgeDot>
                </div>

                <div className="flex items-center">
                    <Label className="font-size-small pr-1">Filter by:</Label>
                    <div className="flex items-center">
                        <CustomRadios
                            items={filterItems}
                            value={filterBy}
                            onChange={(value) => setFilterBy(value as FilterBy)}
                        />
                    </div>
                </div>
            </div>

            <WBarChart options={options} />
        </>
    );
}

export function CustomersSection() {
    return (
        <LightBlock>
            <CustomersSectionContent />
        </LightBlock>
    );
}
