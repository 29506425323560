import D from "./Dashboard.module.css";

import { BadgeDot } from "../../components/Badge.tsx";
import { Separator } from "../../components/Separator.tsx";
import { useThemeVar } from "../../modules/theme/useTheme.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { billingAccountStatsQueryAtom } from "../accounts/accountsQuery.ts";
import { userSignupsQueryAtom } from "../users/usersQuery.ts";
import { HeadingBlock } from "./HeadingBlock.tsx";
import { LightBlock } from "./LightBlock.tsx";
import { PercentageChange } from "./PercentageChange.tsx";
import { monthNames, WBarChart, type WBarChartOptions } from "./WBarChart.tsx";

function UserConversionSectionContent() {
    const limitMonths = 12;
    const billingAccountStats = useSuspenseQueryAtom(billingAccountStatsQueryAtom(limitMonths));
    const userSignups = useSuspenseQueryAtom(userSignupsQueryAtom(limitMonths));

    const colorChart2 = useThemeVar("color-chart-2");
    const colorChart3 = useThemeVar("color-chart-3");
    const colorChart4 = useThemeVar("color-chart-4");

    const last30DaysSignUps = userSignups.last_x_days_signups.find((item) => item.days === 30)?.users ?? 0;
    const last60DaysSignUps = userSignups.last_x_days_signups.find((item) => item.days === 60)?.users ?? 0;
    const prev30DaysSignUps = last60DaysSignUps - last30DaysSignUps;

    const last30DaysFirstBA =
        billingAccountStats.last_x_days_first_billing_accounts.find((item) => item.days === 30)?.users ?? 0;
    const last60DaysFirstBA =
        billingAccountStats.last_x_days_first_billing_accounts.find((item) => item.days === 60)?.users ?? 0;
    const prev30DaysFirstBA = last60DaysFirstBA - last30DaysFirstBA;

    const last30DaysCommitment =
        billingAccountStats.last_x_days_financial_commitment.find((item) => item.days === 30)?.users ?? 0;
    const last60DaysCommitment =
        billingAccountStats.last_x_days_financial_commitment.find((item) => item.days === 60)?.users ?? 0;
    const prev30DaysCommitment = last60DaysCommitment - last30DaysCommitment;

    const xAxisData: string[] = [];

    for (let iteratorMonths = limitMonths; iteratorMonths > 0; iteratorMonths--) {
        const generatedDate = new Date();
        generatedDate.setMonth(generatedDate.getMonth() - (iteratorMonths - 1));
        const month = monthNames[generatedDate.getMonth()];
        const year = generatedDate.getFullYear();
        xAxisData.push(`${month} ${year}`);
    }

    const signedUp = userSignups.monthly_signups.map((item) => item.users).reverse();

    const CreatedFirstBa = billingAccountStats.first_billing_accounts_monthly.map((item) => item.users).reverse();

    const CommittedFinancially = billingAccountStats.financial_commitment_monthly.map((item) => item.users).reverse();

    const options: WBarChartOptions = {
        tooltip: {
            trigger: "axis",
        },
        grid: {
            top: "5px",
            left: "10px",
            right: "30px",
            bottom: "5px",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            data: xAxisData,
            axisLine: {
                lineStyle: {
                    color: "#888888",
                },
            },
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: "Signed up",
                type: "line",
                data: signedUp,
                color: colorChart2,
            },
            {
                name: "Created first BA",
                type: "line",
                data: CreatedFirstBa,
                color: colorChart3,
            },
            {
                name: "Committed financially",
                type: "line",
                data: CommittedFinancially,
                color: colorChart4,
            },
        ],
    };

    return (
        <>
            <div className="flex justify-between gap-1rem pb-3">
                <div>
                    <h2 className="font-size-heading">User Conversion</h2>
                    <p className="pb-3">Signed up VS Created first BA VS Committed financially</p>
                </div>

                <HeadingBlock
                    title="Last 30 days"
                    subtitle={
                        <div>
                            <span className="font-size-default">Signed up</span> {last30DaysSignUps}
                        </div>
                    }
                    className="ml-auto"
                >
                    <PercentageChange last={last30DaysSignUps} prev={prev30DaysSignUps} />
                </HeadingBlock>

                <HeadingBlock
                    title="Last 30 days"
                    subtitle={
                        <div>
                            <span className="font-size-default">Created first BA</span> {last30DaysFirstBA}
                        </div>
                    }
                >
                    <PercentageChange last={last30DaysFirstBA} prev={prev30DaysFirstBA} />
                </HeadingBlock>

                <HeadingBlock
                    title="Last 30 days"
                    subtitle={
                        <div>
                            <span className="font-size-default">Committed financially</span> {last30DaysCommitment}
                        </div>
                    }
                >
                    <PercentageChange last={last30DaysCommitment} prev={prev30DaysCommitment} />
                </HeadingBlock>

                <HeadingBlock
                    title="Total"
                    subtitle={
                        <div>
                            <div>
                                <span className="font-size-default">Signed up</span> {userSignups.total_signups}
                            </div>
                            <div>
                                <span className="font-size-default">Created first BA</span>{" "}
                                {billingAccountStats.total_first_billing_accounts}
                            </div>
                            <div>
                                <span className="font-size-default">Committed financially</span>{" "}
                                {billingAccountStats.total_financial_commitment}
                            </div>
                        </div>
                    }
                />
            </div>

            <Separator />

            <div className="flex gap-1rem font-size-small pt-4 pb-4">
                <BadgeDot color="color-chart-2">Signed up</BadgeDot>

                <BadgeDot color="color-chart-3">Created first BA</BadgeDot>

                <BadgeDot color="color-chart-4">Committed financially</BadgeDot>
            </div>

            <WBarChart options={options} />
        </>
    );
}

export function UserConversionSection() {
    return (
        <LightBlock className={D.Wide}>
            <UserConversionSectionContent />
        </LightBlock>
    );
}
