import type { TooltipComponentFormatterCallback, TooltipComponentFormatterCallbackParams } from "echarts";
import type { BarSeriesOption } from "echarts/charts";
import invariant from "tiny-invariant";
import BD from "../../components/Badge.module.css";
import { BadgeDot } from "../../components/Badge.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { Separator } from "../../components/Separator.tsx";
import { useConfig } from "../../config.ts";
import { useThemeVar } from "../../modules/theme/useTheme.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { usePortal } from "../../utils/react/usePortal.tsx";
import { invoiceTotalsQueryAtom } from "../accounts/accountsQuery.ts";
import { LightBlock } from "./LightBlock.tsx";
import { PercentageChange } from "./PercentageChange.tsx";
/* eslint-disable react-hooks/rules-of-hooks -- Work around ESLint bug not allowing hooks and loops in the same component regardless of order */
import { HeadingBlock } from "./HeadingBlock.tsx";
import { monthNames, WBarChart, type WBarChartOptions } from "./WBarChart.tsx";

function InvoicedRevenueSectionContent() {
    const limitMonths = 12;
    const { allowDebt } = useConfig();
    const data = useSuspenseQueryAtom(invoiceTotalsQueryAtom);

    const tooltipPortal = usePortal();

    const colorText = useThemeVar("color-text");
    const colorChart1 = useThemeVar("color-chart-1");
    const colorChart2 = useThemeVar("color-chart-2");
    const colorChart3 = useThemeVar("color-chart-3");
    const colorChart4 = useThemeVar("color-chart-4");

    const xAxisData: string[] = [];

    for (let iteratorMonths = limitMonths; iteratorMonths > 0; iteratorMonths--) {
        const generatedDate = new Date();
        generatedDate.setMonth(generatedDate.getMonth() - (iteratorMonths - 1));
        const month = monthNames[generatedDate.getMonth()];
        const year = generatedDate.getFullYear();
        xAxisData.push(`${month} ${year}`);
    }

    let invoiceTotalLastMonth = 0;
    let invoiceTotalPrevMonth = 0;

    const filteredData = data
        .filter((item) => (allowDebt ? item.type === "cloud_services" : item.type === "credit_topup"))
        .slice(-limitMonths);

    const payablesData = filteredData.map((item) => item.total_before_tax);
    const vatData = filteredData.map((item) => item.vat_tax);
    const discountData = filteredData.map((item) => Math.abs(item.discount_amount));
    const creditData = filteredData.map((item) => Math.abs(item.credit));
    const payablesPrediction: number[] = [];
    const vatPrediction: number[] = [];

    filteredData.forEach((item, index: number, arr) => {
        const indexPrev: number = item.type === "cloud_services" ? 2 : 3;
        const indexLast: number = item.type === "cloud_services" ? 1 : 2;

        if (index + indexPrev === arr.length) {
            invoiceTotalPrevMonth = item.total_before_tax;
        }

        if (index + indexLast === arr.length) {
            invoiceTotalLastMonth = item.total_before_tax;
        }

        if (index + 1 === filteredData.length) {
            if (item.type === "credit_topup") {
                const inputDate = new Date(item.period_start_month);
                const currentDate = new Date();
                const passedInMillis = currentDate.valueOf() - inputDate.valueOf();
                const passedHours = passedInMillis / (1000 * 60 * 60);
                const year = currentDate.getFullYear();
                const mon = currentDate.getMonth(); // Note: January is 0, February is 1, etc.
                const firstDayOfNextMonth = new Date(year, mon + 1, 1);
                const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth.getTime() - 1);
                lastDayOfCurrentMonth.setHours(23, 59, 59, 999);
                const leftInMillis = lastDayOfCurrentMonth.valueOf() - currentDate.valueOf();
                const leftHours = leftInMillis / (1000 * 60 * 60);

                const prediction = (item.total_before_tax / passedHours) * leftHours;
                const predictionVat = (Math.abs(item.vat_tax) / passedHours) * leftHours;
                payablesPrediction.push(Math.abs(prediction - Math.abs(item.vat_tax)));
                vatPrediction.push(predictionVat + Math.abs(item.vat_tax));
            } else {
                payablesPrediction.push(0);
                vatPrediction.push(0);
            }
        } else {
            payablesPrediction.push(0);
            vatPrediction.push(0);
        }
    });

    const tooltipFormatter: TooltipComponentFormatterCallback<TooltipComponentFormatterCallbackParams> = (params) => {
        invariant(!Array.isArray(params), "Expected single params");
        const { name, value, dataIndex } = params;
        invariant(typeof value === "number", "Expected number value");

        const content = (
            <div>
                <div className="pb-1">
                    <b>{name}</b>
                </div>

                {!!payablesData[dataIndex] && (
                    <BadgeDot color="color-chart-1">
                        Payables (excl. VAT):{" "}
                        <b>
                            <CurrencyBalance value={payablesData[dataIndex]} />
                        </b>
                    </BadgeDot>
                )}

                {!!vatData[dataIndex] && (
                    <BadgeDot color="color-chart-2">
                        VAT:{" "}
                        <b>
                            <CurrencyBalance value={vatData[dataIndex]} />
                        </b>
                    </BadgeDot>
                )}

                {!!creditData[dataIndex] && (
                    <BadgeDot color="color-chart-3">
                        Credit:{" "}
                        <b>
                            <CurrencyBalance value={creditData[dataIndex]} />
                        </b>
                    </BadgeDot>
                )}

                {!!payablesPrediction[dataIndex] && (
                    <BadgeDot color={BD.free}>
                        Payables prediction (excl. VAT):{" "}
                        <b>
                            <CurrencyBalance
                                value={payablesData[dataIndex] + payablesPrediction[dataIndex] + vatData[dataIndex]}
                            />
                        </b>
                    </BadgeDot>
                )}

                {!!discountData[dataIndex] && (
                    <BadgeDot color="color-chart-4">
                        Discount:{" "}
                        <b>
                            <CurrencyBalance value={discountData[dataIndex]} />
                        </b>
                    </BadgeDot>
                )}

                {!!vatPrediction[dataIndex] && (
                    <BadgeDot color={BD.prediction}>
                        VAT prediction:{" "}
                        <b>
                            <CurrencyBalance value={vatPrediction[dataIndex]} />
                        </b>
                    </BadgeDot>
                )}
            </div>
        );

        // return renderToStaticMarkup(content);
        tooltipPortal.setContent(content);
        return tooltipPortal.element;
    };

    const payablesSeries: BarSeriesOption[] = [
        {
            name: "Payables (excl. VAT)",
            type: "bar",
            stack: "total",
            itemStyle: {
                color: colorChart1,
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data: payablesData,
        },
    ];

    const vatSeries: BarSeriesOption[] = [
        {
            name: "VAT",
            type: "bar",
            stack: "total",
            itemStyle: {
                color: colorChart2,
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data: vatData,
        },
    ];

    const creditSeries: BarSeriesOption[] = [
        {
            name: "VAT",
            type: "bar",
            stack: "total",
            itemStyle: {
                color: colorChart3,
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data: creditData,
        },
    ];

    const discountSeries: BarSeriesOption[] = [
        {
            name: "VAT",
            type: "bar",
            stack: "total",
            itemStyle: {
                color: colorChart4,
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data: discountData,
        },
    ];

    const options: WBarChartOptions = {
        tooltip: {
            trigger: "item",
            axisPointer: {
                type: "none",
            },
            borderWidth: 0,
            textStyle: {
                color: colorText,
            },
            extraCssText: "box-shadow: 0 8px 20px rgba(0 0 0 / 10%);",
        },
        grid: {
            top: "5px",
            left: "5px",
            right: "5px",
            bottom: "5px",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: xAxisData,
            axisLine: {
                lineStyle: {
                    color: "#888888",
                },
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
        },
        series: [...payablesSeries, ...vatSeries, ...creditSeries, ...discountSeries],
    };

    return (
        <>
            {tooltipPortal.portalNode}

            <div className="flex justify-between gap-1rem pb-3">
                <div>
                    <h2 className="font-size-heading">Invoiced Revenue</h2>
                    <p className="pb-3">Revenue breakdown and actual payable amount</p>
                </div>

                <HeadingBlock
                    title="Last month payables (excl. VAT)"
                    subtitle={<CurrencyBalance value={invoiceTotalLastMonth} />}
                >
                    <PercentageChange last={invoiceTotalLastMonth} prev={invoiceTotalPrevMonth} />
                </HeadingBlock>
            </div>

            <Separator />

            <div className="flex gap-3 pt-3 pb-4 font-size-small">
                <BadgeDot color="color-chart-1">Payables (excl. VAT)</BadgeDot>

                <BadgeDot color="color-chart-2">VAT</BadgeDot>

                {allowDebt ? (
                    <BadgeDot color="color-chart-3">Credit</BadgeDot>
                ) : (
                    <BadgeDot color={BD.free}>Payables prediction (excl. VAT)</BadgeDot>
                )}

                {allowDebt ? (
                    <BadgeDot color="color-chart-4">Discount</BadgeDot>
                ) : (
                    <BadgeDot color={BD.prediction}>VAT prediction</BadgeDot>
                )}
            </div>

            <WBarChart options={options} />
        </>
    );
}

export function InvoicedRevenueSection() {
    return (
        <LightBlock>
            <InvoicedRevenueSectionContent />
        </LightBlock>
    );
}
