import BD from "../../components/Badge.module.css";
import D from "./Dashboard.module.css";

import type { TooltipComponentFormatterCallback, TooltipComponentFormatterCallbackParams } from "echarts";
import type { BarSeriesOption } from "echarts/charts";
import { useMemo, useState } from "react";
import { Label } from "react-aria-components";
import { sortBy, sumBy } from "remeda";
import invariant from "tiny-invariant";
import { ActionMenu } from "../../components/ActionMenu.tsx";
import { BadgeDot } from "../../components/Badge.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { Separator } from "../../components/Separator.tsx";
import { useConfig } from "../../config.ts";
import { usePricingLocations } from "../../modules/location/query.ts";
import { useThemeVar } from "../../modules/theme/useTheme.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { usePortal } from "../../utils/react/usePortal.tsx";
import { usageBasedRevenueQueryAtom } from "../accounts/accountsQuery.ts";
import { LightBlock } from "./LightBlock.tsx";
import { PercentageChange } from "./PercentageChange.tsx";
/* eslint-disable react-hooks/rules-of-hooks -- Work around ESLint bug not allowing hooks and loops in the same component regardless of order */
import { useAtomValue } from "jotai/react";
import { type ResourceType, resourceTypesEnabledAtom } from "../../modules/api/resourceTypes.tsx";
import type { LocationWithEnabled } from "../../modules/location/location.ts";
import { AdminLocationMenu } from "../AdminLocationSelect.tsx";
import { HeadingBlock } from "./HeadingBlock.tsx";
import { monthNames, WBarChart, type WBarChartOptions } from "./WBarChart.tsx";

interface ProductType {
    id: string;
    title: string;
    order: number;
    isChecked: boolean;
}

function abbreviateNumber(number: number): string {
    const SI_SYMBOL = ["", "k", "M", "B", "T", "Q"];
    const tier = (Math.log10(number) / 3) | 0;
    if (tier === 0) return String(number);
    const suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);
    const scaled = number / scale;
    return scaled.toFixed(0) + suffix;
}

export function UsageBasedRevenueSectionContent() {
    const split = true;
    const limitMonths = 12;
    const creditedWithoutVatKey = "credited_without_vat";

    const { kubernetesEnabled } = useConfig();
    const enabled = useAtomValue(resourceTypesEnabledAtom);
    const colorText = useThemeVar("color-text");
    const colorPrimary = useThemeVar("color-primary");
    const colorChartFree = useThemeVar("color-chart-free");
    const data = useSuspenseQueryAtom(usageBasedRevenueQueryAtom(split, limitMonths));
    const locations = usePricingLocations();

    const getDefaultLocations = (locations: LocationWithEnabled[]) => {
        const items: string[] = locations.map((item) => item.slug);
        return [...items, ""];
    };

    const defaultLocations = useMemo(() => getDefaultLocations(locations), [locations]);

    const [checkedLocations, setCheckedLocations] = useState<string[]>(defaultLocations);

    const tooltipPortal = usePortal();

    const getDefaultTypes = (enabled: Record<ResourceType, boolean>, kubernetesEnabled: boolean) => {
        const types: ProductType[] = [{ id: "CUSTOM_PRODUCT", title: "Custom products", order: 14, isChecked: true }];

        if (enabled.bare_metal) {
            types.push({
                id: "BARE_METAL_LEASE",
                title: "Bare metal",
                order: 1,
                isChecked: true,
            });
        }

        if (enabled.virtual_machine) {
            types.push(
                { id: "VM_COMPUTE", title: "VM compute", order: 2, isChecked: true },
                { id: "BLOCK_STORAGE", title: "VM disk", order: 3, isChecked: true },
                { id: "REPLICA_BACKUP", title: "Backup of disk", order: 4, isChecked: true },
                { id: "REPLICA_SNAPSHOT", title: "Snapshot of disk", order: 5, isChecked: true },
                { id: "LICENSE", title: "Windows license", order: 8, isChecked: true },
            );
        }

        if (enabled.ip_address) {
            types.push(
                {
                    id: "ASSIGNED_FLOATING_IP",
                    title: "Assigned floating IP",
                    order: 6,
                    isChecked: true,
                },
                {
                    id: "UNASSIGNED_FLOATING_IP",
                    title: "Unassigned floating IP",
                    order: 7,
                    isChecked: true,
                },
            );
        }

        if (enabled.bucket) {
            types.push({ id: "OBJECT_STORAGE", title: "Object storage", order: 12, isChecked: true });
        }

        if (enabled.load_balancer) {
            types.push({ id: "LOAD_BALANCER", title: "Load balancer", order: 13, isChecked: true });
        }

        if (enabled.managed_service) {
            types.push(
                {
                    id: "MANAGED_SERVICE_mariadb",
                    title: "Managed Service MariaDB",
                    order: 9,
                    isChecked: true,
                },
                {
                    id: "MANAGED_SERVICE_postgresql",
                    title: "Managed Service PostgreSQL",
                    order: 10,
                    isChecked: true,
                },
            );

            if (kubernetesEnabled) {
                types.push({
                    id: "MANAGED_SERVICE_kubernetes",
                    title: "Managed Service Kubernetes",
                    order: 11,
                    isChecked: true,
                });
            }
        }

        return sortBy(types, (l) => l.order);
    };

    const defaultTypes = useMemo(() => getDefaultTypes(enabled, kubernetesEnabled), [enabled, kubernetesEnabled]);

    const [productTypes, setProductTypes] = useState<ProductType[]>(defaultTypes);

    const checkedProductTypes = productTypes.filter((item) => item.isChecked);
    const locationProducts = data.filter((item) => checkedLocations.includes(item.location_slug ?? ""));
    const filteredProducts = locationProducts.filter((item) =>
        checkedProductTypes.map((type) => type.id).includes(item.product_type ?? ""),
    );
    const filteredUsageCredited = locationProducts.filter((item) => item.type === creditedWithoutVatKey);

    const periodStartMonths: string[] = [];
    const xAxisData: string[] = [];
    const totalData: number[] = [];
    const warrenShareData: number[] = [];
    const usageCreditedData: number[] = [];
    const predictionData: number[] = [];
    const momData: number[] = [];
    let usagePrediction = 0;
    let warrenPrediction = 0;
    let momPrediction = 0;

    for (let iteratorMonths = limitMonths; iteratorMonths > 0; iteratorMonths--) {
        const generatedDate = new Date();
        generatedDate.setMonth(generatedDate.getMonth() - (iteratorMonths - 1));
        const month = monthNames[generatedDate.getMonth()];
        const year = generatedDate.getFullYear();
        let formattedDate = new Date(Date.UTC(year, generatedDate.getMonth(), 1, 0, 0, 0)).toISOString();
        formattedDate = `${formattedDate.split(".")[0]}Z`;
        periodStartMonths.push(formattedDate);
        xAxisData.push(`${month} ${year}`);
    }

    periodStartMonths.forEach((month, index) => {
        const total = sumBy(
            filteredProducts.filter((item) => item.period_start_month === month),
            (i) => i.subtotal,
        );
        totalData.push(total);

        const credited = sumBy(
            filteredUsageCredited.filter((item) => item.period_start_month === month),
            (i) => i.subtotal,
        );
        usageCreditedData.push(credited);

        warrenShareData.push(((total - (credited ? credited : 0)) / 100) * 20);

        const mom = totalData[index - 1] ? ((totalData[index] - totalData[index - 1]) / totalData[index - 1]) * 100 : 0;
        momData.push(mom);

        if (index === periodStartMonths.length - 1) {
            const inputDate = new Date(month);
            const currentDate = new Date();
            const passedInMillis = currentDate.valueOf() - inputDate.valueOf();
            const passedHours = passedInMillis / (1000 * 60 * 60);

            const year = currentDate.getFullYear();
            const mon = currentDate.getMonth(); // Note: January is 0, February is 1, etc.
            const firstDayOfNextMonth = new Date(year, mon + 1, 1);
            const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth.getTime() - 1);
            lastDayOfCurrentMonth.setHours(23, 59, 59, 999);
            const leftInMillis = lastDayOfCurrentMonth.valueOf() - currentDate.valueOf();
            const leftHours = leftInMillis / (1000 * 60 * 60);

            usagePrediction = (total / passedHours) * leftHours;
            warrenPrediction = (usagePrediction / 100) * 20;

            if (totalData[index - 1]) {
                momPrediction = ((total + usagePrediction - totalData[index - 1]) / totalData[index - 1]) * 100;
            }

            predictionData.push(usagePrediction);
        } else {
            predictionData.push(0);
        }
    });

    const usageLastTotal = totalData.length > 1 ? totalData[limitMonths - 2] : 0;
    const usagePrevTotal = totalData.length > 2 ? totalData[limitMonths - 3] : 0;
    const usageLastWarren = warrenShareData.length > 1 ? warrenShareData[limitMonths - 2] : 0;
    const usage3Last = totalData.slice(-5, -1);
    const usage3Prev = totalData.slice(-6, -2);
    const mom3Last =
        usage3Last.length > 1
            ? ((usage3Last[usage3Last.length - 1] / usage3Last[0]) ** (1 / (usage3Last.length - 1)) - 1) * 100
            : 0;
    const mom3Prev =
        usage3Prev.length > 1
            ? ((usage3Prev[usage3Prev.length - 1] / usage3Prev[0]) ** (1 / (usage3Prev.length - 1)) - 1) * 100
            : 0;
    const usage12Last = totalData.slice(-12, -1);
    const usage12Prev = totalData.slice(-13, -2);
    const mom12Last =
        usage12Last.length > 1
            ? ((usage12Last[usage12Last.length - 1] / usage12Last[0]) ** (1 / (usage12Last.length - 1)) - 1) * 100
            : 0;
    const mom12Prev =
        usage12Prev.length > 1
            ? ((usage12Prev[usage12Prev.length - 1] / usage12Prev[0]) ** (1 / (usage12Prev.length - 1)) - 1) * 100
            : 0;

    const tooltipFormatter: TooltipComponentFormatterCallback<TooltipComponentFormatterCallbackParams> = (params) => {
        invariant(!Array.isArray(params), "Expected single params");
        const { name, value, seriesName, dataIndex } = params;
        invariant(typeof value === "number", "Expected number value");

        const content = (
            <div>
                <div className="pb-1">
                    <b>{name}</b>
                </div>

                <BadgeDot color="color-chart-free">
                    {seriesName}:{" "}
                    <b>
                        <CurrencyBalance value={value} />
                    </b>
                </BadgeDot>

                <div className="py-1">
                    <Separator />
                </div>

                <BadgeDot color="color-chart-2">
                    Usage:{" "}
                    <b>
                        <CurrencyBalance value={totalData[dataIndex]} />
                    </b>
                </BadgeDot>

                {dataIndex === totalData.length - 1 && (
                    <BadgeDot color={BD.free}>
                        Usage prediction:{" "}
                        <b>
                            <CurrencyBalance value={totalData[dataIndex] + usagePrediction} />
                        </b>
                    </BadgeDot>
                )}

                {!!momData[dataIndex] && !(dataIndex === momData.length - 1) && (
                    <BadgeDot color="color-chart-3">
                        Usage MoM: <b>{momData[dataIndex].toFixed(1)}%</b>
                    </BadgeDot>
                )}

                {dataIndex === momData.length - 1 && (
                    <BadgeDot color="color-chart-3">
                        Usage MoM prediction: <b>{momPrediction ? momPrediction.toFixed(1) : "N/A"}%</b>
                    </BadgeDot>
                )}

                <div className="py-1">
                    <Separator />
                </div>

                {!!usageCreditedData[dataIndex] && (
                    <BadgeDot color={undefined} style={{ color: "#fdd835" }}>
                        Total amount credited:{" "}
                        <b>
                            <CurrencyBalance value={usageCreditedData[dataIndex]} />
                        </b>
                    </BadgeDot>
                )}

                <BadgeDot color="color-text">
                    Warren share:{" "}
                    <b>
                        <CurrencyBalance value={warrenShareData[dataIndex]} />
                    </b>
                </BadgeDot>

                {dataIndex === warrenShareData.length - 1 && (
                    <BadgeDot color={BD.prediction}>
                        Warren share prediction:{" "}
                        <b>
                            <CurrencyBalance value={warrenShareData[dataIndex] + warrenPrediction} />
                        </b>
                    </BadgeDot>
                )}
            </div>
        );

        // return renderToStaticMarkup(content);
        tooltipPortal.setContent(content);
        return tooltipPortal.element;
    };

    const seriesData = checkedProductTypes.map((type): BarSeriesOption => {
        const items = filteredProducts.filter((item) => item.product_type === type.id);

        const data = periodStartMonths.map((month) =>
            sumBy(
                items.filter((item) => item.period_start_month === month),
                (i) => i.subtotal,
            ),
        );

        return {
            name: type.title,
            type: "bar",
            stack: "total",
            itemStyle: {
                borderColor: "transparent",
                borderWidth: 1,
                color: colorPrimary,
            },
            emphasis: {
                itemStyle: {
                    color: colorChartFree,
                },
            },
            tooltip: {
                formatter: tooltipFormatter,
            },
            data,
        };
    });

    const predictionItem: BarSeriesOption = {
        name: "Prediction",
        type: "bar",
        stack: "total",
        itemStyle: {
            borderColor: "transparent",
            borderWidth: 1,
            color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.01,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.01,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.05,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.05,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.06,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.06,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.1,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.1,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.11,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.11,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.15,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.15,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.16,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.16,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.2,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.2,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.21,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.21,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.25,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.25,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.26,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.26,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.3,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.3,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.31,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.31,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.35,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.35,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.36,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.36,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.4,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.4,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.41,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.41,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.45,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.45,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.46,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.46,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.5,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.5,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.51,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.51,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.55,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.55,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.56,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.56,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.6,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.6,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.61,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.61,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.65,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.65,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.66,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.66,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.7,
                        color: colorChartFree,
                    },

                    {
                        offset: 0,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.01,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.01,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.05,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.05,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.06,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.06,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.7,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.7,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.71,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.71,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.75,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.75,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.76,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.76,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.8,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.8,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.81,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.81,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.85,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.85,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.86,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.86,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.9,
                        color: colorChartFree,
                    },

                    {
                        offset: 0.9,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.91,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.91,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.95,
                        color: colorChartFree,
                    },
                    {
                        offset: 0.95,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.96,
                        color: colorPrimary,
                    },
                    {
                        offset: 0.96,
                        color: colorChartFree,
                    },
                    {
                        offset: 1,
                        color: colorChartFree,
                    },
                ],
                global: false,
            },
        },
        emphasis: {
            itemStyle: {
                color: colorChartFree,
            },
        },
        tooltip: {
            formatter: tooltipFormatter,
        },
        data: predictionData,
    };

    const options: WBarChartOptions = {
        tooltip: {
            trigger: "item",
            axisPointer: {
                type: "none",
            },
            borderWidth: 0,
            textStyle: {
                color: colorText,
            },
            extraCssText: "box-shadow: 0 8px 20px rgba(0 0 0 / 10%);",
        },
        grid: {
            top: "5px",
            left: "5px",
            right: "5px",
            bottom: "5px",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: xAxisData,
            axisLine: {
                lineStyle: {
                    color: "#888888",
                },
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (value) => abbreviateNumber(value),
            },
        },
        series: [...seriesData, predictionItem],
    };

    function onChangeLocation(value: string[]) {
        setCheckedLocations(value);
    }

    function onToggle(id: string) {
        const updatedProductTypes = productTypes.map((product) =>
            product.id === id ? { ...product, isChecked: !product.isChecked } : product,
        );
        setProductTypes(updatedProductTypes);
    }

    function setAllChecked(isChecked: boolean) {
        const updatedProductTypes = productTypes.map((product) => ({
            ...product,
            isChecked: isChecked,
        }));
        setProductTypes(updatedProductTypes);
    }

    return (
        <>
            {tooltipPortal.portalNode}
            <div className="flex justify-between gap-1rem pb-3">
                <div>
                    <h2 className="font-size-heading">Usage Based Revenue</h2>
                    <p>Total resources used in monetary value</p>
                </div>

                <HeadingBlock
                    title="CMGR 12 months"
                    subtitle={mom12Last && mom12Last !== Infinity ? `${mom12Last.toFixed(2)}%` : "N/A"}
                    className="ml-auto"
                >
                    <PercentageChange last={mom12Last} prev={mom12Prev} />
                </HeadingBlock>

                <HeadingBlock
                    title="CMGR 3 months"
                    subtitle={mom3Last && mom3Last !== Infinity ? `${mom3Last.toFixed(2)}%` : "N/A"}
                >
                    <PercentageChange last={mom3Last} prev={mom3Prev} />
                </HeadingBlock>

                <HeadingBlock
                    title="Last MoM growth"
                    subtitle={<CurrencyBalance value={usageLastTotal - usagePrevTotal} />}
                >
                    <PercentageChange last={usageLastTotal} prev={usagePrevTotal} />
                </HeadingBlock>

                <HeadingBlock title="Last month total" subtitle={<CurrencyBalance value={usageLastTotal} />}>
                    <div>
                        <span className="color-muted">Warren</span>: <CurrencyBalance value={usageLastWarren} />
                    </div>
                </HeadingBlock>
            </div>

            <Separator />

            <div className="flex justify-between pt-4 pb-4">
                <div className="flex gap-1rem font-size-small">
                    <BadgeDot color="color-chart-2">Usage</BadgeDot>

                    <BadgeDot color={BD.free}>Usage prediction</BadgeDot>
                </div>

                <AdminLocationMenu
                    noLocationEnabled
                    value={checkedLocations}
                    locations={locations}
                    onChange={onChangeLocation}
                />
            </div>

            <div className="flex items-center pb-3">
                <Label className="pr-1">Filter by product type:</Label>
                <ActionMenu
                    header={
                        <div className="flex-1 flex gap-1rem justify-between">
                            <WButton color="primary" variant="ghost" size="xs" action={() => setAllChecked(true)}>
                                Select All
                            </WButton>
                            <div>|</div>
                            <WButton color="primary" variant="ghost" size="xs" action={() => setAllChecked(false)}>
                                Unselect All
                            </WButton>
                        </div>
                    }
                    onAction={({ id }) => onToggle(id)}
                    selectedKeys={checkedProductTypes.map((item) => item.id)}
                    items={productTypes}
                    selectionMode="multiple"
                >
                    <WButton color="default" variant="basic" size="md" ariaLabel="Sort by" action={undefined}>
                        <span className="text-ellipsis max-w-15rem">
                            {checkedProductTypes.length
                                ? productTypes.length === checkedProductTypes.length
                                    ? "All"
                                    : checkedProductTypes.map((item) => item.title).join(", ")
                                : "Please select"}
                        </span>
                    </WButton>
                </ActionMenu>
            </div>

            <WBarChart options={options} />
        </>
    );
}

export function UsageBasedRevenueSection() {
    return (
        <LightBlock className={D.Wide}>
            <UsageBasedRevenueSectionContent />
        </LightBlock>
    );
}
